
import './App.css';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import Logo from "./images/f2.png"

function App() {
  return (
   <></>
  );
}

export default App;
